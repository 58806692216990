// MapaCiudad.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Configurar ícono predeterminado de Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

function MapaCiudad({ ciudad }) {
    const navigate = useNavigate();
    const [markers, setMarkers] = useState([]);
    const mapRef = useRef(null);

    // Función para procesar y estandarizar direcciones
    // Función para procesar y estandarizar direcciones
const procesarDireccion = (direccion) => {
    if (!direccion) return '';

    // Convertir a mayúsculas para facilitar la comparación
    const direccionUpper = direccion.toUpperCase();

    // Eliminar prefijos problemáticos como PJE, CALLE, AV., etc.
    const prefijosProblematicos = ['PJE.', 'CALLE', 'AVENIDA', 'PAS.', 'PASAJE'];
    let direccionLimpia = direccionUpper;

    prefijosProblematicos.forEach((prefijo) => {
        const regex = new RegExp(`^${prefijo}\\s+`, 'i'); // Buscar prefijo al inicio
        direccionLimpia = direccionLimpia.replace(regex, '').trim(); // Reemplazarlo
    });

    // Dividir la dirección en partes
    const partes = direccionLimpia.split(',');

    // Extraer calle y número (primer elemento)
    const calleNumero = partes[0]?.trim() || '';

    // Determinar ciudad basada en la presencia en la dirección o por parámetro
    let ciudadFinal = '';
    if (direccionUpper.includes('ANTOFAGASTA')) {
        ciudadFinal = 'Antofagasta';
    } else if (direccionUpper.includes('ARICA')) {
        ciudadFinal = 'Arica';
    } else {
        ciudadFinal = ciudad; // Usar la ciudad pasada por parámetro
    }

    // Construir dirección estandarizada
    const direccionProcesada = `${calleNumero}, ${ciudadFinal}, Chile`;
    return direccionProcesada;
};


    useEffect(() => {
        const geocodeDireccion = async (direccion) => {
            try {
                const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(direccion)}`;
                const response = await fetch(url);
                const data = await response.json();
                if (data && data.length > 0) {
                    const { lat, lon } = data[0];
                    return [parseFloat(lat), parseFloat(lon)];
                }
                console.warn(`No se encontraron coordenadas para la dirección: ${direccion}`);
                return null;
            } catch (error) {
                console.error(`Error al geocodificar la dirección: ${direccion}`, error);
                return null;
            }
        };

        const cargarMarkers = async () => {
            try {
                const [pedidosAricaData, pedidosAntofagastaData, pedidosCallCenterData] = await Promise.all([
                    fetch('https://pedidos.ayvgas.cl/pedidos/pedidos.json').then((res) => res.json()),
                    fetch('https://pedidos.ayvgas.cl/pedidos/pedidos_antofagasta.json').then((res) => res.json()),
                    fetch('https://pedidos.ayvgas.cl/pedidos/pedido_callcenter.json').then((res) => res.json()),
                ]);

                // Ensure data are arrays
                const pedidosArica = Array.isArray(pedidosAricaData)
                    ? pedidosAricaData
                    : Object.values(pedidosAricaData);

                const pedidosAntofagasta = Array.isArray(pedidosAntofagastaData)
                    ? pedidosAntofagastaData
                    : Object.values(pedidosAntofagastaData);

                const pedidosCallCenter = Array.isArray(pedidosCallCenterData)
                    ? pedidosCallCenterData
                    : Object.values(pedidosCallCenterData);

                const pedidosCombinados = [
                    ...pedidosArica.map((pedido) => ({ ...pedido, ciudad: 'Arica' })),
                    ...pedidosAntofagasta.map((pedido) => ({ ...pedido, ciudad: 'Antofagasta' })),
                    ...pedidosCallCenter.map((pedido) => ({
                        ...pedido,
                        ciudad: pedido.campo_yy || 'Arica',
                    })),
                ];

                const pedidosFiltrados = pedidosCombinados.filter(
                    (pedido) => pedido.ciudad.toLowerCase() === ciudad.toLowerCase()
                );

                const markersConCoordenadas = [];

                for (const pedido of pedidosFiltrados) {
                    const direccionProcesada = procesarDireccion(pedido.campo_7);
                    const coordenadas = await geocodeDireccion(direccionProcesada);
                    if (coordenadas) {
                        markersConCoordenadas.push({ coordenadas, pedido });
                    }
                }

                setMarkers(markersConCoordenadas);
            } catch (error) {
                console.error("Error al cargar los marcadores:", error);
            }
        };

        cargarMarkers();
    }, [ciudad]);

    const getCenter = () => {
        if (ciudad.toLowerCase() === 'arica') {
            return [-18.478253, -70.312599];
        } else if (ciudad.toLowerCase() === 'antofagasta') {
            return [-23.652360, -70.395401];
        } else {
            return [0, 0];
        }
    };

    return (
        <div style={{ height: '100vh', width: '100vw', margin: 0, padding: 0 }}>
            <button
                onClick={() => navigate('/welcome/pedidos')}
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    zIndex: 1000,
                    padding: '10px 20px',
                    background: '#ff8000',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                }}
            >
                Volver a Pedidos
            </button>
            <MapContainer center={getCenter()} zoom={12} style={{ height: '100%', width: '100%' }} ref={mapRef}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; OpenStreetMap contributors'
                />
                {markers.map((marker, idx) => (
                    <Marker key={idx} position={marker.coordenadas}>
                        <Popup>
                            <strong>Dirección:</strong> {marker.pedido.campo_7}<br />
                            <strong>Pedido N°:</strong> {marker.pedido.campo_2}<br />
                            <strong>Cliente:</strong> {marker.pedido.campo_4}<br />
                            <strong>Producto:</strong> {marker.pedido.campo_8}
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
}

export default MapaCiudad;
