import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse, differenceInSeconds } from 'date-fns';
import es from 'date-fns/locale/es'; // Para manejar el idioma español
import '../styles/PedidosHistoricos.css';

// Importación de imágenes para los semáforos
import img1 from '../images/1.png';
import img2 from '../images/2.png';
import img3 from '../images/3.png';
import img4 from '../images/4.png';
import img5 from '../images/5.png';
// Eliminamos la importación de eliminarImg ya que la columna de acciones se elimina
// import eliminarImg from '../images/eliminar.png';

function PedidosHistoricos() {
    const [pedidos, setPedidos] = useState([]);
    const [filtros, setFiltros] = useState({
        fechaInicio: null,
        fechaFin: null,
        estado: '',
        movil: '',
        origen: '',
        ciudad: '',
        busqueda: ''
    });
    const [origenesUnicos, setOrigenesUnicos] = useState([]);
    const [ultimaVersionJson, setUltimaVersionJson] = useState(null);

    const normalizeDate = (date) => {
        if (!date) return null;
        const normalizedDate = new Date(date);
        normalizedDate.setHours(0, 0, 0, 0);
        return normalizedDate;
    };

    useEffect(() => {
        // Configura los filtros de fecha para hoy al montar el componente
        const today = new Date();
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            fechaInicio: normalizeDate(today),
            fechaFin: normalizeDate(today)
        }));
    }, []);

    const cargarPedidosHistoricos = async () => {
        try {
            const response = await fetch('https://pedidos.ayvgas.cl/pedidos/pedido_callcenter_historico.json', { cache: 'no-store' });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setPedidos(data);

            // Obtener origenes únicos para el filtro
            const origenes = [...new Set(data.map((pedido) => pedido.campo_12))];
            setOrigenesUnicos(origenes);
        } catch (error) {
            console.error('Error al cargar los pedidos históricos:', error);
        }
    };

    useEffect(() => {
        cargarPedidosHistoricos();
        const intervalId = setInterval(() => {
            cargarPedidosHistoricos();
        }, 30000);

        return () => clearInterval(intervalId);
    }, [ultimaVersionJson]);

    const handleFiltroChange = (e) => {
        setFiltros({
            ...filtros,
            [e.target.name]: e.target.value
        });
    };

    const parsearFecha = (fechaStr) => {
        if (!fechaStr || typeof fechaStr !== 'string') {
            return null;
        }
        // campo_5 viene con formato "dd-MM-yyyy, HH:mm:ss" o similar
        const [fecha] = fechaStr.split(',');
        try {
            const parsedDate = parse(fecha.trim(), 'dd-MM-yyyy', new Date());
            parsedDate.setHours(0, 0, 0, 0);
            return parsedDate;
        } catch (error) {
            console.error("Error al parsear la fecha:", error);
            return null;
        }
    };

    const filtrarPedidos = (pedido) => {
        if (!pedido) return false;

        const { fechaInicio, fechaFin, estado, movil, origen, ciudad, busqueda } = filtros;

        // Filtro de búsqueda en todas las columnas
        const busquedaNormalizada = busqueda.toLowerCase();
        const camposConcatenados = Object.values(pedido).join(' ').toLowerCase();
        if (busqueda && !camposConcatenados.includes(busquedaNormalizada)) {
            return false;
        }

        // Filtro de fecha
        const fechaPedido = parsearFecha(pedido.campo_5);
        if (!fechaPedido) return false;
        const normalizedFechaInicio = normalizeDate(fechaInicio);
        const normalizedFechaFin = normalizeDate(fechaFin);
        if (normalizedFechaInicio && fechaPedido < normalizedFechaInicio) return false;
        if (normalizedFechaFin && fechaPedido > normalizedFechaFin) return false;

        // Filtros específicos: estado, móvil, origen, ciudad
        if (estado && pedido.campo_6 !== estado) return false;
        if (movil && pedido.campo_11 !== movil) return false;
        if (origen && pedido.campo_12 !== origen) return false;
        if (ciudad && pedido.campo_yy !== ciudad) return false;

        return true;
    };

    const calcularDiferenciaTiempo = (fechaPedido) => {
        const fecha = parsearFecha(fechaPedido);
        if (!fecha) return 0;
        const ahora = new Date();
        const diferenciaMinutos = Math.floor((ahora - fecha) / (1000 * 60));
        return diferenciaMinutos;
    };

    const obtenerSemaforoImagen = (ciudad, tiempo) => {
        if (!tiempo) return img5;

        const tiempoEnSegundos = differenceInSeconds(
            parse(tiempo, 'HH:mm:ss', new Date()),
            parse('00:00:00', 'HH:mm:ss', new Date())
        );

        // Semáforos dependiendo de la ciudad
        if (ciudad === "Arica") {
            if (tiempoEnSegundos < 450) return img1;   // < 7.5 minutos
            if (tiempoEnSegundos < 900) return img2;   // < 15 minutos
            if (tiempoEnSegundos < 1350) return img3;  // < 22.5 minutos
            if (tiempoEnSegundos < 1800) return img4;  // < 30 minutos
            return img5;                               // >= 30 minutos
        } else if (ciudad === "Antofagasta") {
            if (tiempoEnSegundos < 600) return img1;   // < 10 minutos
            if (tiempoEnSegundos < 1200) return img2;  // < 20 minutos
            if (tiempoEnSegundos < 1800) return img3;  // < 30 minutos
            if (tiempoEnSegundos < 2400) return img4;  // < 40 minutos
            return img5;                               // >= 40 minutos
        }
        return img5;
    };

    const eliminarDuplicados = async () => {
        const confirmacion = window.confirm("¿Estás seguro de que deseas eliminar los duplicados?");
        if (!confirmacion) return;

        try {
            const response = await fetch('https://pedidos.ayvgas.cl/pedidos/limpiaduplicados.php', {
                method: 'POST', // Asumo que es un POST, si es GET, cámbialo
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({}) // Si se necesitan parámetros, agrégalos aquí
            });

            if (response.ok) {
                const result = await response.json();
                alert("Duplicados eliminados exitosamente.");
                cargarPedidosHistoricos(); // Recargar los pedidos después de la limpieza
            } else {
                throw new Error(`Error al eliminar duplicados: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error al eliminar duplicados:', error);
            alert("Ocurrió un error al eliminar los duplicados.");
        }
    };

    return (
        <div className="pedidos-historicos-container">
            <h1>Pedidos Históricos</h1>

            <div className="filtros-container">
                <input
                    type="text"
                    name="busqueda"
                    value={filtros.busqueda}
                    placeholder="Buscar en todas las columnas..."
                    onChange={handleFiltroChange}
                />

                <DatePicker
                    selected={filtros.fechaInicio}
                    onChange={(date) => setFiltros({ ...filtros, fechaInicio: normalizeDate(date) })}
                    placeholderText="Desde fecha"
                    dateFormat="dd-MM-yyyy"
                    locale={es}
                    isClearable
                />
                <DatePicker
                    selected={filtros.fechaFin}
                    onChange={(date) => setFiltros({ ...filtros, fechaFin: normalizeDate(date) })}
                    placeholderText="Hasta fecha"
                    dateFormat="dd-MM-yyyy"
                    locale={es}
                    isClearable
                />

                <select name="estado" value={filtros.estado} onChange={handleFiltroChange}>
                    <option value="">Filtrar por estado</option>
                    <option value="Despacho">Despacho</option>
                    <option value="Entregado">Entregado</option>
                    <option value="Pendiente">Pendiente</option>
                    <option value="Anulado">Anulado</option>
                </select>

                <input
                    type="text"
                    name="movil"
                    value={filtros.movil}
                    placeholder="Filtrar por móvil..."
                    onChange={handleFiltroChange}
                />

                <select name="origen" value={filtros.origen} onChange={handleFiltroChange}>
                    <option value="">Filtrar por origen</option>
                    {origenesUnicos.map((origen, index) => (
                        <option key={index} value={origen}>
                            {origen}
                        </option>
                    ))}
                </select>

                <select name="ciudad" value={filtros.ciudad} onChange={handleFiltroChange}>
                    <option value="">Filtrar por ciudad</option>
                    <option value="Arica">Arica</option>
                    <option value="Antofagasta">Antofagasta</option>
                </select>

                {/* Botón para eliminar duplicados */}
                <button
                    className="eliminar-duplicados-btn"
                    onClick={eliminarDuplicados}
                >
                    Eliminar Duplicados
                </button>
            </div>

            <table className="pedidos-historicos-tabla">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>N° del Pedido</th>
                        <th>Semáforo</th>
                        <th>Teléfono</th>
                        <th>Fecha</th>
                        <th>Estado</th>
                        <th>Dirección</th>
                        <th>Producto1</th>
                        <th>Producto2</th>
                        <th>Producto3</th>
                        <th>Producto4</th>
                        <th>Movil</th>
                        <th>Origen</th>
                        <th>Ciudad</th>
                        <th>Tiempo</th>
                        {/* Eliminamos la columna Acciones */}
                        {/* <th>Acciones</th> */}
                    </tr>
                </thead>
                <tbody>
                    {pedidos.filter(filtrarPedidos).map((pedido, index) => {
                        const semaforoImg = obtenerSemaforoImagen(pedido.campo_yy, pedido.campo_xx);
                        return (
                            <tr key={index}>
                                <td>{pedido.campo_1 || index + 1}</td>
                                <td>{pedido.campo_2}</td>
                                <td><img src={semaforoImg} alt="Semáforo" width="40" /></td>
                                <td>{pedido.campo_4}</td>
                                <td>{pedido.campo_5}</td>
                                <td>{pedido.campo_6}</td>
                                <td>{pedido.campo_7}</td>
                                <td>{pedido.campo_8}</td>
                                <td>{pedido.campo_9}</td>
                                <td>{pedido.campo_13}</td>
                                <td>{pedido.campo_14}</td>
                                <td>{pedido.campo_11}</td>
                                <td>{pedido.campo_12}</td>
                                <td>{pedido.campo_yy}</td>
                                <td>{pedido.campo_xx}</td>
                                {/* Eliminamos la columna Acciones */}
                                {/* <td>
                                    <img
                                        src={eliminarImg}
                                        alt="Eliminar Pedido"
                                        width="30"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => eliminarPedido(pedido.campo_1, index)}
                                    />
                                </td> */}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

}

export default PedidosHistoricos;
