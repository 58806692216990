import React, { useState } from 'react';
import axios from 'axios';

function EnviarMensajesTelegram() {
    const [message, setMessage] = useState('');
    const [photo, setPhoto] = useState(null);
    const [recipient, setRecipient] = useState('group'); // group, canal, bot
    const [includeHacerPedido, setIncludeHacerPedido] = useState(false);
    const [includeConocerPrecios, setIncludeConocerPrecios] = useState(false);
    const [includeCallCenter, setIncludeCallCenter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleSendMessage = async () => {
        try {
            if (!message.trim() && !photo) {
                setResponseMessage('Debes escribir un mensaje o adjuntar una imagen.');
                return;
            }

            if (photo) {
                if (!['image/jpeg', 'image/png', 'image/gif'].includes(photo.type)) {
                    setResponseMessage('El tipo de archivo no es válido. Solo se permiten imágenes JPEG, PNG o GIF.');
                    return;
                }

                if (photo.size > 5 * 1024 * 1024) {
                    setResponseMessage('La foto no puede superar los 5 MB.');
                    return;
                }
            }

            setLoading(true);

            const formData = new FormData();
            formData.append('message', message);
            if (photo) {
                formData.append('photo', photo);
            }
            formData.append('recipient', recipient);

            // Agregar info sobre los botones seleccionados
            if (includeHacerPedido) formData.append('hacer_pedido', 'true');
            if (includeConocerPrecios) formData.append('conocer_precios', 'true');
            if (includeCallCenter) formData.append('contactar_call_center', 'true');

            const response = await axios.post('https://pedidos.ayvgas.cl/telegram/sendmessage.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setResponseMessage(response.data.message || 'Mensaje enviado correctamente.');
            setMessage('');
            setPhoto(null);
            setIncludeHacerPedido(false);
            setIncludeConocerPrecios(false);
            setIncludeCallCenter(false);
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Error al enviar el mensaje.';
            setResponseMessage(errorMessage);
            console.error('Error al enviar el mensaje:', error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };

    const toggleButton = (setter, current) => {
        setter(!current);
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Enviar Mensajes a Telegram</h2>
            <p style={styles.promotion}>
                🔥 ¡Aprovecha esta promoción única por tiempo limitado! 🎉 Solo disponibles por pocos días, no dejes pasar esta gran oportunidad. 😍
            </p>

            {responseMessage && (
                <div style={styles.response}>
                    <p>{responseMessage}</p>
                </div>
            )}

            <div style={styles.content}>
                {/* Formulario de entrada */}
                <div style={styles.form}>
                    <textarea
                        style={styles.textarea}
                        placeholder="Escribe tu mensaje aquí"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    ></textarea>

                    <input
                        style={styles.fileInput}
                        type="file"
                        accept="image/*"
                        onChange={(e) => setPhoto(e.target.files[0])}
                    />

                    <div style={{ marginBottom: '15px' }}>
                        <p><strong>Enviar por:</strong></p>
                        <label style={{ marginRight: '10px', cursor: 'pointer' }}>
                            <input
                                type="radio"
                                name="recipient"
                                value="group"
                                checked={recipient === 'group'}
                                onChange={(e) => setRecipient(e.target.value)}
                                style={{ marginRight: '5px' }}
                            />
                            Grupo Telegram
                        </label>
                        <label style={{ marginRight: '10px', cursor: 'pointer' }}>
                            <input
                                type="radio"
                                name="recipient"
                                value="canal"
                                checked={recipient === 'canal'}
                                onChange={(e) => setRecipient(e.target.value)}
                                style={{ marginRight: '5px' }}
                            />
                            Canal Telegram
                        </label>
                        <label style={{ cursor: 'pointer' }}>
                            <input
                                type="radio"
                                name="recipient"
                                value="bot"
                                checked={recipient === 'bot'}
                                onChange={(e) => setRecipient(e.target.value)}
                                style={{ marginRight: '5px' }}
                            />
                            Bot (Depuración)
                        </label>
                    </div>

                    <div style={{ marginBottom: '15px' }}>
                        <p><strong>Botones para el mensaje (Telegram):</strong></p>
                        <div style={styles.buttonRow}>
                            <div
                                style={{
                                    ...styles.toggleButton,
                                    backgroundColor: includeHacerPedido ? '#4caf50' : '#ddd',
                                    color: includeHacerPedido ? '#fff' : '#333'
                                }}
                                onClick={() => toggleButton(setIncludeHacerPedido, includeHacerPedido)}
                            >
                                🛒 Hacer Pedido
                            </div>
                            <div
                                style={{
                                    ...styles.toggleButton,
                                    backgroundColor: includeConocerPrecios ? '#4caf50' : '#ddd',
                                    color: includeConocerPrecios ? '#fff' : '#333'
                                }}
                                onClick={() => toggleButton(setIncludeConocerPrecios, includeConocerPrecios)}
                            >
                                💰 Conocer Precios
                            </div>
                            <div
                                style={{
                                    ...styles.toggleButton,
                                    backgroundColor: includeCallCenter ? '#4caf50' : '#ddd',
                                    color: includeCallCenter ? '#fff' : '#333'
                                }}
                                onClick={() => toggleButton(setIncludeCallCenter, includeCallCenter)}
                            >
                                📞 Contactar al Call Center
                            </div>
                        </div>
                    </div>

                    <button style={styles.sendButton} onClick={handleSendMessage} disabled={loading}>
                        {loading ? 'Enviando...' : 'Enviar Mensaje'}
                    </button>
                </div>

                {/* Vista previa del chat */}
                <div style={styles.phone}>
                    {/* Header del chat */}
                    <div style={styles.chatHeader}>
                        <h3 style={styles.chatTitle}>Vista Previa</h3>
                    </div>

                    {/* Mensajes del chat */}
                    <div style={styles.chatContainer}>
                        {(photo || message) && (
                            <div style={styles.messageBubbleOutgoing}>
                                {photo && (
                                    <img
                                        style={styles.messageImage}
                                        src={URL.createObjectURL(photo)}
                                        alt="Previsualización"
                                    />
                                )}
                                {message && <p>{message}</p>}
                            </div>
                        )}

                        {(includeHacerPedido || includeConocerPrecios || includeCallCenter) && (
                            <div style={styles.messageBubbleOutgoing}>
                                <p>Botones seleccionados:</p>
                                <ul style={{ paddingLeft: '20px' }}>
                                    {includeHacerPedido && <li>🛒 Hacer Pedido</li>}
                                    {includeConocerPrecios && <li>💰 Conocer Precios</li>}
                                    {includeCallCenter && <li>📞 Contactar al Call Center</li>}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '20px',
        fontSize: '1.5em',
        color: '#333',
    },
    promotion: {
        textAlign: 'center',
        marginBottom: '20px',
        fontSize: '1.1em',
        color: '#D32F2F', // Un tono rojizo
    },
    response: {
        marginBottom: '10px',
        padding: '10px',
        backgroundColor: '#e6f7ff',
        border: '1px solid #91d5ff',
        borderRadius: '5px',
        color: '#0050b3',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    form: {
        width: '48%',
        maxWidth: '500px',
    },
    textarea: {
        width: '100%',
        height: '80px',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        marginBottom: '15px',
        fontSize: '1em',
        resize: 'none',
    },
    fileInput: {
        width: '100%',
        marginBottom: '15px',
    },
    sendButton: {
        width: '100%',
        padding: '10px',
        backgroundColor: '#f44336', // Cambiamos a rojo
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '1em',
    },
    phone: {
        width: '48%',
        height: '640px',
        borderRadius: '30px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        fontFamily: 'Arial, sans-serif',
    },
    chatHeader: {
        backgroundColor: '#0088cc',
        color: '#fff',
        padding: '15px',
        textAlign: 'center',
    },
    chatTitle: {
        margin: 0,
        fontSize: '1.2em',
    },
    chatContainer: {
        padding: '10px',
        backgroundColor: '#e5ddd5',
        height: 'calc(100% - 70px)',
        overflowY: 'auto',
    },
    messageBubbleOutgoing: {
        maxWidth: '70%',
        margin: '10px 0 10px auto',
        padding: '10px',
        backgroundColor: '#dcf8c6',
        borderRadius: '10px',
        textAlign: 'left',
        wordWrap: 'break-word',
    },
    messageImage: {
        marginBottom: '10px',
        maxWidth: '100%',
        borderRadius: '5px',
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    toggleButton: {
        padding: '10px',
        borderRadius: '5px',
        textAlign: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        border: '1px solid #ccc',
    },
};

export default EnviarMensajesTelegram;
