import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import '../styles/GPS.css'

import amarillo from '../images/map marker/amarillo.png'
import azul from '../images/map marker/azul.png'
import celeste from '../images/map marker/celeste.png'
import morado from '../images/map marker/morado.png'
import naranjaFuerte from '../images/map marker/naranja fuerte.png'
import naranja from '../images/map marker/naranja.png'
import rojoItaliano from '../images/map marker/rojo italiano.png'
import rojo from '../images/map marker/rojo.png'
import verdeOscuro from '../images/map marker/verde oscuro.png'
import verde from '../images/map marker/verde.png'
import violeta from '../images/map marker/violeta.png'

const iconMapping = {
  M01: amarillo,
  M05: azul,
  M13: celeste,
  M15: morado,
  M36: naranjaFuerte,
  M50: violeta,
  M60: rojoItaliano,
  M61: verde,
  M62: verdeOscuro
}

const createCustomIcon = (iconUrl, size = 40) => {
  return L.icon({
    iconUrl,
    iconSize: [size, size],
    iconAnchor: [size / 2, size],
    popupAnchor: [0, -size / 2]
  })
}

function GPS() {
  const [locations, setLocations] = useState([])
  const [error, setError] = useState(null)

  const fetchLocations = async () => {
    try {
      const response = await fetch(`https://pedidos.ayvgas.cl/ubicaciones.json?t=${Date.now()}`)
      if (!response.ok) {
        throw new Error(`HTTP error al leer ubicaciones.json: ${response.status}`)
      }
      const data = await response.json()
      if (data.status === 'OK' && data.data) {
        setLocations(data.data)
        setError(null)
      } else {
        throw new Error('El JSON no contiene datos válidos.')
      }
    } catch (err) {
      console.error('Error al leer ubicaciones.json:', err)
      setError(err.message)
    }
  }

  useEffect(() => {
    const updateData = async () => {
      await fetchLocations()
    }
    updateData()
    const interval = setInterval(updateData, 10000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div
      className="main-content gps"
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        width: '100vw',
        margin: 0,
        position: 'relative'
      }}
    >
      {error && (
        <div
          style={{
            position: 'absolute',
            top: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'red',
            color: 'white',
            padding: '10px',
            zIndex: 1000,
            borderRadius: '5px'
          }}
        >
          Error: {error}
        </div>
      )}
      <div style={{ flex: 1, position: 'relative' }}>
        <MapContainer center={[-18.4783, -70.3126]} zoom={13} style={{ height: '100%', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />
          {locations
            .filter(location => location.latitude > -19 && location.latitude < -17)
            .map((location, index) => (
              <Marker
                key={`arica-${index}`}
                position={[location.latitude, location.longitude]}
                icon={createCustomIcon(iconMapping[location.username] || violeta, 40)}
              >
                <Popup>
                  <strong>Usuario:</strong> {location.username} <br />
                  <strong>Estado:</strong> {location.status} <br />
                  <strong>Último Reporte:</strong> {new Date(location.lastReport * 1000).toLocaleString()}
                </Popup>
              </Marker>
            ))}
        </MapContainer>
      </div>
      <div style={{ flex: 1, position: 'relative' }}>
        <MapContainer center={[-23.65, -70.4]} zoom={13} style={{ height: '100%', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />
          {locations
            .filter(location => location.latitude < -22 && location.latitude > -24)
            .map((location, index) => (
              <Marker
                key={`antofagasta-${index}`}
                position={[location.latitude, location.longitude]}
                icon={createCustomIcon(iconMapping[location.username] || violeta, 40)}
              >
                <Popup>
                  <strong>Usuario:</strong> {location.username} <br />
                  <strong>Estado:</strong> {location.status} <br />
                  <strong>Último Reporte:</strong> {new Date(location.lastReport * 1000).toLocaleString()}
                </Popup>
              </Marker>
            ))}
        </MapContainer>
      </div>
    </div>
  )
}

export default GPS
